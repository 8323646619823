import { DeleteBin } from '@styled-icons/remix-line';
import { DeleteBin as DeleteBinFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function DeleteIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <DeleteBin />
    </Icon>
  );
}

export function DeleteFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <DeleteBinFill />
    </Icon>
  );
}
